// Corporate colors
$green: #003d21
$yellow: #ffe32e
$white: #ffffff
$black: #000000

// Palette colors
$greenDark: #004703
$greenLight: #165929
$greenVeryLight: #97d44e
$gold: #b3a512
$yellowGreen: #8d872b
$orange: #ed812f

// Fonts
$font-main: 'Montserrat', sans-serif
$font-title: 'Montserrat', sans-serif
$font-alternative: 'Cupcake', sans-serif

// Sizes
$container-large-width: 1025px
$mobile-max-width: 550px
$video-width: 765px
$video-height: 430px

$size-tablet-mq: 1024px
$size-landscape-mq: 420px
$size-mobile-mq: 550px

:root 
    --color-green-dark: #{$green}
    --color-green-light: #14b072