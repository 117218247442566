@font-face
  font-family: 'Boogaloo'
  src: url('https://fonts.gstatic.com/s/boogaloo/v10/kmK-Zq45GAvOdnaW6y1C9ys.woff2') format('woff2')
  font-display: swap

// LICENSE: Cupcake font is personal use only, please use another font or pay for it!
@font-face
  font-family: 'Cupcake'
  src: url('/assets/fonts/cupcake/cupcake.woff') format('woff'), url('/assets/fonts/cupcake/cupcake.woff2') format('woff2')
  font-display: swap

@font-face
  font-family: 'Foco Corp'
  src: url('/assets/fonts/foco-corp/foco-corp.woff2') format('woff2'), url('/assets/fonts/foco-corp/foco-corp.ttf') format('ttf')
  font-display: swap

@font-face
  font-family: 'Foco Corp'
  src: url('/assets/fonts/foco-corp/foco-corp--bold.woff2') format('woff2'), url('/assets/fonts/foco-corp/foco-corp--bold.ttf') format('ttf')
  font-weight: bold
  font-display: swap

@font-face
  font-family: 'Foco Corp'
  src: url('/assets/fonts/foco-corp/foco-corp--italic.woff2') format('woff2'), url('/assets/fonts/foco-corp/foco-corp--italic.ttf') format('ttf')
  font-style: italic
  font-display: swap


@font-face
  font-family: 'Hopper'
  src: url('/assets/fonts/hopper/hip-hopper-w01-regular.woff2?v=1') format('woff2')
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'HipHopper'
  src: url('/assets/fonts/hip-hopper/hip-hopper-pb-regular.woff2') format('woff2')
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'Roboto'
  src: url('/assets/fonts/roboto/roboto-light.ttf')
  font-display: swap

@font-face
  font-family: 'Tubic'
  src: url('/assets/fonts/tubic/fbtubic-sans-regular.otf') format('opentype')
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'Montserrat'
  src: url('/assets/fonts/montserrat/montserrat-medium.ttf')
  font-display: swap

@font-face
  font-family: 'Raleway'
  src: url('/assets/fonts/raleway/Raleway-ExtraBold.ttf')
  font-weight: 900
  font-display: swap

@font-face
  font-family: 'Raleway'
  src: url('/assets/fonts/raleway/Raleway-SemiBold.ttf')
  font-weight: 700
  font-display: swap