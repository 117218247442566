.som-default-theme
  $main-color: #004927
  $accent-color: white
  $secondary-color: #14B072
  $secondary-font-color: #80A493
  .som-main
    &-color
      color: $main-color
      &-hover
        &:hover
          color: $main-color
    &-back
      background-color: $main-color
    &-border-color
      border-color: $main-color
    &-border-top
      border-top: 1px solid $main-color
    &-border-bottom
      border-bottom: 1px solid $main-color
    &-stroke
      stroke: $main-color
    &-fill
      fill: $main-color
    &:hover
      color: white
      &:hover
        stroke: $main-color

  .som-accent
    &-color
      color: $accent-color
    &-back
      background-color: $accent-color
    &-border-top
      border-top: 1px solid $accent-color
    &-border-bottom
      border-bottom: 1px solid $accent-color
    &-stroke
      stroke: $accent-color
      &-hover
        &:hover
          stroke: $accent-color
    &:hover
      color: white
    &-fill
      fill: $accent-color

  .som-secondary
    &-color
      color: $secondary-color
      &-hover
        &:hover
          color: $secondary-color !important
    &-back
      background-color: $secondary-color
      &-hover
        &:hover
          background-color: $secondary-color
    &-border-top
      border-top: 1px solid $secondary-color
    &-border-bottom
      border-bottom: 1px solid $secondary-color
    &-stroke
      stroke: $secondary-color
      &-hover
        &:hover
          stroke: $secondary-color

  .som-secondary-font
    &-color
      color: $secondary-font-color
    &-back
      background-color: $secondary-font-color
    &-border-top
      border-top: 1px solid $secondary-font-color
    &-border-bottom
      border-bottom: 1px solid $secondary-font-color


  .webkit-scrollbar
    ::-webkit-scrollbar-track
      background: white
    ::-webkit-scrollbar-thumb
      background: $main-color
    ::-webkit-scrollbar-thumb:hover
      background: $main-color

  // OVERRIDE sf librairy
  .sf
    &-main
      &-color
        color: $main-color !important
        &-hover
          &:hover
            color: $main-color
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            color: $main-color
      &-back
        background-color: $main-color
      &-border-top
        border-top: 1px solid $main-color
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            border-top: 1px solid $main-color
      &-border-bottom
        border-bottom: 1px solid $main-color
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            border-top: 1px solid $main-color
      &-stroke
        stroke: $main-color
        &:hover
          stroke: $main-color

    &-secondary
      &-color
        color: $secondary-color
        &-hover
          &:hover
            color: $secondary-color
      &-back
        background-color: $secondary-color
        &-hover-full
          &:hover, &:active, &:focus
            background-color: $secondary-color
      &-border-top
        border-top: 1px solid $secondary-color
      &-border-bottom
        border-bottom: 1px solid $secondary-color
      &-stroke
        stroke: $secondary-color
        &-hover
          &:hover
            stroke: $secondary-color
      &-fill
        fill: $secondary-color

    &-secondary-font
      &-color
        color: $secondary-font-color
        &-placeholder
          &::placeholder
            color: $secondary-font-color
      &-border-top
        border-top: 1px solid $secondary-font-color
      &-border-bottom
        border-bottom: 1px solid $secondary-font-color
        /* SEARCH-PAGE */
        .som-search
          .sf
            &-search-bar
              height: 30px
              font-size: 25px
              letter-spacing: 0.2px
              padding-bottom: 13px
              padding-top: 13px
              margin-bottom: 13px
    &-icon
      &-stroke
        stroke: white
      &-fill
        fill: white
    &-accent-back:hover
      color: white !important

  // override icons in application
  // white with green inside
  .som-desktop-social, .som-desktop-top-item, .magazine-footer-bottom-container-social, .footer-right-social, .som-mobile-menu-top-item
    cursor: pointer
    color: white
    // fill for IE
    fill: white
    .som-social-network-icon
      fill: white
      &-size
        height: 32px
        width: 32px
    .sf-icon-stroke
      stroke: $main-color
    .sf-icon-fill
      fill: $main-color
    svg:hover .sf-icon-stroke
      stroke: $secondary-color
    svg:hover .sf-icon-fill
      fill: $secondary-color

  // green with white inside
  .som-mobile-menu-social, .som-mobile-header-top-right, .som-mobile-header-top-left
    cursor: pointer
    color: $main-color
    fill: $main-color
    .som-social-network-icon-size
      height: 48px
      width: 48px
    .sf-icon-stroke
      stroke: white
    .sf-icon-fill
      fill: white

  // specific to the app sombrero
  /* LIB */
  .sf
    &-title
        font-family: var(--font-family-bold)
        font-weight: bold
        line-height: 1
    &-text
        font-family: var(--font-family-regular)
        font-weight: 400
    &-button
        font-family: var(--font-family-bold)
        font-weight: bold
    &-headline
      &-title, &-list-item
        color: $main-color
    &-item-icon
        color: $accent-color
    &-newsletter
        a
            color: $accent-color
            text-decoration: underline
    &-icon-button
        color: $main-color
        font-family: var(--font-family-bold)
        font-weight: bold
        &:hover
            color: $secondary-color
            .sf-icon-button-icon
                fill: $secondary-color
            div:first-child
                color: $secondary-color
        &-icon
            fill: $main-color
            &:hover
                fill: $secondary-color
    &-item-stat
      color: $accent-color
    &-item-text
      color: $main-color
    &-section
      &-image-content, &-image-icon-content, &-slider-content, &-text, &-video
        &-text
          li
            list-style-type: disc !important
          a
            text-decoration: underline
      &-image, &-image-icon, &-text, &-video, &-slider
        color: $main-color !important
    &-highlight
      width: auto !important
      .sf-item-content-subtitle
        color: $accent-color !important
      &-grid
        &-item-star
          .sf-item-content-subtitle
            font-size: 60px !important
            color: $accent-color !important

  /* FROM FIELD TO PLATE */
  .som-field-plate
    margin-bottom: 0px !important
    .sf
      &-banner
        &-content
          &-label
            font-size: 60px
            line-height: 60px
      &-section
        &-video
          &-iframe
            height: auto
            width: auto
        &-image-icon
          &-content
            border-bottom: 1px solid $main-color

  /* VEGETABLE */
  .som-vegetable
    .sf
      &-section
        &-image-icon
          margin: 50px 0px 50px 0px

  /* CORPORATE */
  .som-corporate, .som-menu-page
    .sf
      &-slider
        height: 450px
        &-breadcrumb-container
          bottom: 25px

  /* COOKIE-BANNER */
  .som-cookie-banner
    background-color: $main-color
    font-family: 'Montserrat'
    font-size: 12px
    &-button
      &-content
        color: $main-color
        font-family: 'Montserrat'
        font-weight: bold

  .som-generic
    color: $main-color

  /* SEARCH-PAGE */
  .som-search
    .sf
      &-search-bar
        font-family: var(--font-family-regular)
        font-weight: 400
        color: $main-color
      &-item-content-subtitle
        font-size: 17px
  .som-product-grid, .som-recipe-grid
    .sf-slide-grid-col, ul, li
      height: 192px !important


@media screen and (max-width: $size-tablet-mq)
  .som-green-theme
    --icon-size: 27px
    /* FROM FIELD TO PLATE */
    .som-field-plate
      .sf
        &-banner
          &-content
            &-label
              font-size: 30px
              line-height: 30px
    .som-product-grid, .som-recipe-grid
      .sf-slide-grid-col, ul, li
        height: 137px !important
    /* CORPORATE */
    .som-corporate, .som-menu-page
      .sf
        &-slider
          height: 380px
          &-breadcrumb-container
            bottom: 25px

@media screen and (max-width: $size-mobile-mq)
  .som-green-theme
    --icon-size: 24px
    .sf
      &-highlight
        &-grid
          &-item-star
            .sf-item-content
              &-subtitle
                font-size: 30px !important
          &-50, &-100
            .sf-item-content
              &-subtitle
                font-size: 15px !important
      &-grid
        .sf-item-icon-title
          font-size: 12px
    /* VEGETABLE */
    .som-vegetable
      .sf
        &-item
          &-content
            left: 10px
            &-subtitle
              font-size: 15px

    /* FROM FIELD TO PLATE */
    .som-field-plate
      .sf
        &-section
          &-video
            &-title, &-text
              font-size: 16px
          &-image-icon
            &-content
              &-title
                font-size: 20px
              &-text
                font-size: 16px
    /* COOKIE-BANNER */
    .som-cookie-banner
      &-text
        font-size: 10px
      &-button-content
        font-size: 10px
    .som-product-grid, .som-recipe-grid
      .sf-slide-grid-col, ul, li
          height: 175px !important
