// @import "app/shared/angular-material/angular-material.theming"
@import "styles/_vars"
@import "styles/fonts"
@import "styles/fonts-country"
@import "assets/themes/default-theme.sass"
@import "assets/themes/magazine-theme.sass"
// @import "assets/themes/be-green-theme.sass"
// @import "assets/themes/ee-theme.sass"
// @import "assets/themes/gr-theme.sass"
// @import "assets/themes/gr-magazin-theme.sass"
// @import "assets/themes/hu-green-theme.sass"
// @import "assets/themes/light-hu-green-theme.sass"
// @import "assets/themes/il-green-theme.sass"
// @import "assets/themes/lt-theme.sass"
// @import "assets/themes/lv-theme.sass"


// SIZE DEVICES MEDIAS QUERIES
$size-tablet-mq: 1024px
$size-landscape-mq: 420px
$size-mobile-mq: 550px


html, body
  height: 100%
body
  margin: 0
  &.direction--rtl
    direction: rtl
    .som-filter-checkbox-input-form label

      padding-left: 0
      padding-right: 1.5rem

      &:before
        right: 0
        left: auto
      &:after
        left: auto
        right: 2px

      span
        margin-left: 0
        margin-right: 0.5rem
       
ul
  margin: 0
li
  list-style: none
a
  color: inherit
  text-decoration: none
h1, h2, h3
  margin: 0

.sf-autocomplete-panel
  font-family: Montserrat
  font-weight: 500
  top: 15px
.sf-option .sf-highlighted
  font-family: Montserrat
  font-weight: 500
  padding: 0px
  color: #004927
.som-item-search-grid
  .sf-item-content-subtitle
    font-size: 18px
  .sf-item-content
    width: 80%

.som
  &-bloc
    margin: auto
    margin-top: 32px
    margin-bottom: 32px
    width: 1025px
  &-video
    &-center
     .sf-section-video
        .sf-section-video-iframe
          margin: auto
    &-right
     .sf-section-video
        .sf-section-video-iframe
          margin-left: auto
  &-section
    margin-bottom: 50px
  &-container:last-child
    margin-bottom: 64px
  &-center
    display: flex
    align-content: center
    justify-content: center
  &-space
    margin-top: 32px
    margin-bottom: 32px
  &-banner
    &-standard
      height: 370px !important



// GENERAL RULES (margins/padding/layout)

.highlight
  font-weight: bold
  
.block
  display: block
  
.flex
  display: flex

.flex-wrap
  flex-wrap: wrap

.justify-content-center
  justify-content: center

.items-center
  align-items: center

.p-c-xs
  padding: 0.5rem
.p-c-sm
  padding: 1rem
.p-c-md
  padding: 1.5rem
.p-c-lg
  padding: 2rem
.p-c-xl
  padding: 2.5rem

.mt-c-xs
  margin-top: 0.5rem
.mt-c-sm
  margin-top: 1rem
.mt-c-md
  margin-top: 1.5rem
.mt-c-lg
  margin-top: 2rem
.mt-c-xl
  margin-top: 2.5rem

.mb-c-xs
  margin-bottom: 0.5rem
.mb-c-sm
  margin-bottom: 1rem
.mb-c-md
  margin-bottom: 1.5rem
.mb-c-lg
  margin-bottom: 2rem
.mb-c-xl
  margin-bottom: 2.5rem

.mr-c-xs
  margin-right: 0.5rem
.mr-c-sm
  margin-right: 1rem
.mr-c-md
  margin-right: 1.5rem
.mr-c-lg
  margin-right: 2rem
.mr-c-xl
  margin-right: 2.5rem

.ml-c-xs
  margin-left: 0.5rem
.ml-c-sm
  margin-left: 1rem
.ml-c-md
  margin-left: 1.5rem
.ml-c-lg
  margin-left: 2rem
.ml-c-xl
  margin-left: 2.5rem

.underline
  text-decoration: underline

.bold
  font-weight: bold


@media screen and (max-width: $size-tablet-mq)
  .som
    &-bloc
      margin: 0 15px
      margin-top: 50px
      width: calc(100% - 30px)
    &-container:last-child
      margin-bottom: 50px
    &-space
      margin-top: 30px
  .flex
    justify-content: center
@media screen and (max-width: $size-mobile-mq)
  .flex-mobile-column
    flex-direction: column
  .som-item-search-grid
    .sf-item-content-subtitle
      font-size: 16px
  .som
    &-bloc
      margin: 0 12px
      margin-top: 30px
      width: calc(100% - 24px)
    &-container:last-child
      margin-bottom: 30px
    &-space
      margin-top: 20px
    &-banner
      &-standard
        height: 250px !important
