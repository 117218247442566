$font-regular-default: 'Montserrat', sans-serif
$font-medium-default: 'Montserrat', sans-serif
$font-semi-bold-default: 'Montserrat', sans-serif
$font-bold-default: 'Montserrat', sans-serif

$font-regular-belgium: 'Roboto', sans-serif
$font-medium-belgium: 'Roboto', sans-serif
$font-semi-bold-belgium: 'Hopper', sans-serif
$font-bold-belgium: 'Hopper', sans-serif

$font-regular-greece: 'Foco Corp', sans-serif
$font-medium-greece: 'Foco Corp', sans-serif
$font-semi-bold-greece: 'Foco Corp', sans-serif
$font-bold-greece: 'Foco Corp', sans-serif

$font-regular-il: 'Tubic', sans-serif
$font-medium-il: 'Tubic', sans-serif
$font-semi-bold-il: 'Tubic', sans-serif
$font-bold-il: 'Tubic', sans-serif

$default-main-color: #004927
$default-back-color: #004927
$default-accent-color: #004927
$default-accent-back: #ffe32e

$secondary-color: #00512C
$secondary-font-color: #80A493

$magazine-main-color: #14B072
$magazine-back-color: #14B072
$magazine-accent-color: white
$magazine-accent-back: #14B072
$magazine-secondary-color: #00512C
$magazine-secondary-font-color: #80A493

$gr-magazine-main-color: #99ba59
$gr-magazine-back-color: #99ba59

$gr-magazine-accent-color: white
$gr-magazine-secondary-color: #00512C
$gr-magazine-secondary-font-color: #80A493

.som-default-theme, .som-magazine-theme

    &.lang-default
        --font-family-regular: #{$font-regular-default}
        --font-family-medium: #{$font-medium-default}
        --font-family-semi-bold: #{$font-semi-bold-default}
        --font-family-bold: #{$font-bold-default}



        --magazine-main-color: #{$magazine-main-color}
        --magazine-back-color: #{$magazine-back-color}
        --magazine-accent-color: #{$magazine-accent-color}
        --magazine-secondary-color: #{$magazine-secondary-color}
        --magazine-secondary-font-color: #{$magazine-secondary-font-color}

        

        .som-font
            &-regular
                font-family: $font-regular-default
                font-weight: 400
            &-medium
                font-family: $font-medium-default
                font-weight: 500
                font-style: normal
                font-stretch: normal
                line-height: 1
                letter-spacing: 0.2px
            &-semibold
                font-family: $font-semi-bold-default
                font-weight: 600
            &-bold
                font-family: $font-bold-default
                font-weight: bold

    &.lang-be
        --font-family-regular: #{$font-regular-belgium}
        --font-family-medium: #{$font-medium-belgium}
        --font-family-semi-bold: #{$font-semi-bold-belgium}
        --font-family-bold: #{$font-bold-belgium}

        .som-font
            &-regular
                font-family: $font-regular-belgium
            &-medium
                font-family: $font-medium-belgium
            &-semibold
                font-family: $font-semi-bold-belgium
            &-bold
                font-family: $font-bold-belgium
                font-weight: 300


        // Spécificités pour rendre la police belge lisible
        .sf-title
            font-weight: 500
        
        div.som-menu-item, div.som-menu-item-link
            letter-spacing: 1px
            font-size: 25px
            font-weight: 500

        ul.som-menu-item-dropdown
            letter-spacing: 1px
            font-size: 25px
            font-weight: 400

        div.footer-expansion-panel-title, div.som-footer-center-group-title,
        div.sf-item-content-title, div.sf-item-text-content-title
            letter-spacing: 1px
            font-size: 20px
            font-weight: 300

        a.som-corporate-link-item
            letter-spacing: 1px
            font-size: 24px
            font-weight: 400

        div.sf-item-content-subtitle, h1.sf-item-icon-title, h2.sf-item-icon-title, h3.sf-item-icon-title,
        div.sf-item-icon-title, h1.sf-headline-title,  h3.sf-headline-title
            letter-spacing: 1px
            font-size: 22px
            font-weight: 500

        h2.sf-headline-title, div.sf-headline-title
            letter-spacing: 1px
            font-size: 32px
            font-weight: 500

        div.som-filter-checkbox-input-title, div.som-filter-checkbox-input-block-title
            font-size: 22px

        div.sf-banner-content-label, h1.sf-banner-content-label, h2.sf-banner-content-label, h3.sf-banner-content-label,
        div.sf-slide-content-label, h1.sf-slide-content-label, h2.sf-slide-content-label, h3.sf-slide-content-label
            letter-spacing: 1px
            line-height: 60px
            font-size: 60px
            font-weight: 500

        div.sf-section-text-title, h1.sf-section-text-title, h2.sf-section-text-title, h3.sf-section-text-title,
        div.som-recipe-container, div.sf-item-recipe-content-title, a.som-menu-page-link-item,
        div.som-ep-header-title, div.sf-section-slider-content,
        div.sf-section-video-title, h1.sf-section-video-title, h2.sf-section-video-title, h3.sf-section-video-title
            letter-spacing: 1px
            font-weight: 500

        div.sf-ep-header, div.som-recipe-container-preparation-list-title
            font-size: 20px

        .som-field-plate
            .sf-title, .sf-text
                font-size: 30px

        .som-product-list-containerfluid, 
        .som-recipe-container-read-also, 
        .som-product-discover-grid, 
        .som-search-mob-packshot
            .sf-grid-row-item
                border-bottom: solid 1px lightgrey


    &.lang-gr
        --font-family-regular: #{$font-regular-greece}
        --font-family-medium: #{$font-medium-greece}
        --font-family-semi-bold: #{$font-semi-bold-greece}
        --font-family-bold: #{$font-bold-greece}

        --magazine-main-color: #{$gr-magazine-main-color}
        --magazine-back-color: #{$gr-magazine-back-color}

        --magazine-accent-color: #{$gr-magazine-accent-color}
        --magazine-secondary-color: #{$gr-magazine-secondary-color}
        --magazine-secondary-font-color: #{$gr-magazine-secondary-font-color}

        .som-font
            &-regular
                font-family: $font-regular-greece
                font-weight: 400
            &-medium
                font-family: $font-medium-greece
                font-weight: 500
                font-style: normal
                font-stretch: normal
                line-height: 1
                letter-spacing: 0.2px
            &-semibold
                font-family: $font-semi-bold-greece
                font-weight: 600
            &-bold
                font-family: $font-bold-greece
                font-weight: bold

    &.lang-il
        --font-family-regular: #{$font-regular-il}
        --font-family-medium: #{$font-medium-il}
        --font-family-semi-bold: #{$font-semi-bold-il}
        --font-family-bold: #{$font-bold-il}
        .som-font
            &-regular
                font-family: $font-regular-il
                font-weight: 700
            &-medium
                font-family: $font-medium-il
                font-weight: 700
                font-style: normal
                font-stretch: normal
                line-height: 1
                letter-spacing: 0.2px
            &-semibold
                font-family: $font-semi-bold-il
                font-weight: 700
            &-bold
                font-family: $font-bold-il
                font-weight: bold