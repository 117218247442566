
.som-magazine-theme
  button.secondary
    background-color: var(--magazine-main-color)!important
    color: white!important
    
  .som-main
    &-color
      color: var( --magazine-main-color) 
      &-hover
        &:hover
          color: var( --magazine-main-color)
    &-back
      background-color: var( --magazine-main-color)
    &-border-color
      border-color: var(--magazine-main-color)
    &-border-top
      border-top: 1px solid var( --magazine-main-color)
    &-border-bottom
      border-bottom: 1px solid var( --magazine-main-color)
    &-stroke
      stroke: var( --magazine-main-color)
      &:hover
        stroke: var( --magazine-main-color)
    &-fill
      fill: var( --magazine-main-color)

  .som-accent
    &-color
      color: var(--magazine-accent-color)
    &-back
      background-color: var(--magazine-accent-color)
    &-border-top
      border-top: 1px solid var(--magazine-accent-color)
    &-border-bottom
      border-bottom: 1px solid var(--magazine-accent-color)
    &-stroke
      stroke: var(--magazine-accent-color)
      &-hover
        &:hover
          stroke: var(--magazine-accent-color)
    &-fill
      fill: var(--magazine-accent-color)


  .som-secondary
    &-color
      color: var(--magazine-secondary-color)
      &-hover
        &:hover
          color: var(--magazine-secondary-color)
    &-back
      background-color: var(--magazine-secondary-color)
    &-border-top
      border-top: 1px solid var(--magazine-secondary-color)
    &-border-bottom
      border-bottom: 1px solid var(--magazine-secondary-color)
    &-stroke
      stroke: var(--magazine-secondary-color)
      &-hover
        &:hover
          stroke: var(--magazine-secondary-color)

  .som-secondary-font
    &-color
      color: var(--magazine-secondary-font-color)
    &-back
      background-color: var(--magazine-secondary-font-color)
    &-border-top
      border-top: 1px solid var(--magazine-secondary-font-color)
    &-border-bottom
      border-bottom: 1px solid var(--magazine-secondary-font-color)

  .som-lighter-font
    &-color
      color: #8AD7B9
    &-back
      background-color: #8AD7B9
    &-border-top
      border-top: 1px solid #8AD7B9
    &-border-bottom
      border-bottom: 1px solid #8AD7B9


  /* MIXINS */
  =som-regular()
    font-family: 'Montserrat'
    font-weight: 400
  =som-medium()
    font-family: 'Montserrat'
    font-weight: 500
  =som-semibold()
    font-family: 'Montserrat'
    font-weight: 600
  =som-bold()
    font-family: 'Montserrat'
    font-weight: bold

  .som-header
    &-back
      fill: white
    &-menu
      &-logo-text
        font-family: 'Montserrat'
        font-size: 12px
        text-transform: initial
        letter-spacing: 0.16px
        font-weight: 800
        bottom: 35px !important

  .webkit-scrollbar
    ::-webkit-scrollbar-track
      background: white
    ::-webkit-scrollbar-thumb
      background: var( --magazine-main-color)
    ::-webkit-scrollbar-thumb:hover
      background: var( --magazine-main-color)

  // OVERRIDE sf librairy
  .sf
    &-main
      &-color
        color: var( --magazine-main-color) !important
        &-hover
          &:hover
            color: var( --magazine-main-color) !important
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            color: var( --magazine-main-color)
      &-back
        background-color: var( --magazine-main-color) !important
      &-border-top
        border-top: 1px solid var( --magazine-main-color)
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            border-top: 1px solid var( --magazine-main-color)
      &-border-bottom
        border-bottom: 1px solid var( --magazine-main-color)
        &-mobile
          @media screen and (max-width: $size-tablet-mq)
            border-top: 1px solid var( --magazine-main-color)
      &-stroke
        stroke: var( --magazine-main-color)
        &-hover
          &:hover
            stroke: var( --magazine-main-color)

    &-secondary
      &-color
        color: var(--magazine-secondary-color)
        &-hover
          &:hover
            color: var(--magazine-secondary-color)
      &-back
        background-color: var(--magazine-secondary-color)
        &-hover-full
          &:hover, &:active, &:focus
            background-color: var(--magazine-secondary-color)
      &-border-top
        border-top: 1px solid var(--magazine-secondary-color)
      &-border-bottom
        border-bottom: 1px solid var(--magazine-secondary-color)
      &-stroke
        stroke: var(--magazine-secondary-color)
        &-hover
          &:hover
            stroke: var(--magazine-secondary-color)
      &-fill
        fill: var(--magazine-secondary-color)
    &-item-stat
      color: white
      .sf-text
        color: white

    &-secondary-font
      &-color
        color: var(--magazine-secondary-font-color)
        &-placeholder
          &::placeholder
            color: var(--magazine-secondary-font-color)
      &-border-top
        border-top: 1px solid var(--magazine-secondary-font-color)
      &-border-bottom
        border-bottom: 1px solid var(--magazine-secondary-font-color)
    &-accent-back:hover
      color: white !important

  // override icons in application
  // white with green inside
  .som-desktop-social, .som-desktop-top-item, .magazine-footer-bottom-container-social, .som-mobile-menu-top-item
    cursor: pointer
    color: white
    // fill for IE
    fill: white
    .som-social-network-icon
      fill: white
      &-size
        height: 32px
        width: 32px
    .sf-icon-stroke
      stroke: var( --magazine-main-color)
    .sf-icon-fill
      fill: var( --magazine-main-color)
    svg:hover .sf-icon-stroke
      stroke: var(--magazine-secondary-color)
    svg:hover .sf-icon-fill
      fill: var(--magazine-secondary-color)

  // green with white inside
  .som-mobile-header-top-right, .som-mobile-header-top-left
    cursor: pointer
    color: var(--magazine-secondary-color)
    fill: var(--magazine-secondary-color)
    .sf-icon-fill
      fill: white
    .sf-icon-stroke
      stroke: white

  // specific to the app sombrero
  /* LIB */
  .sf
    &-title
      +som-bold()
      line-height: 1
      letter-spacing: 0.2px
    &-text
      +som-regular()
      color: var(--magazine-secondary-color)
    &-button
      +som-bold()
      color: var( --magazine-main-color)
      &:hover
        background-color: var(--magazine-secondary-color) 
        color: var(--magazine-accent-color)
    &-headline
      &-title, &-list-item
        color: var( --magazine-main-color)
    &-icon-button
      color: var( --magazine-main-color)
      +som-bold()
      font-size: 12px
      text-transform: uppercase
      &-icon
        fill: var( --magazine-main-color) 
        &:hover
          fill: var(--magazine-secondary-color) 
    &-item-text, &-item-text-horizontal
      color: var( --magazine-main-color)
    &-section
      &-image-content, &-image-icon-content, &-slider-content, &-text, &-video
        &-text
          li
            list-style-type: disc !important
    &-section-image, &-section-image-icon, &-section-text, &-section-video, &-section-slider
      color: var( --magazine-main-color) 
    &-previous
      &-articles
        .sf-item
          &-text-horizontal-content-title
            font-size: 16px
    &-item
      &-content
        color: var(--magazine-accent-color)
